import styles from "./App.module.scss";

function DefaultInfo() {
  return (
    <>
      <h1 className={styles.mainHeading}>Hello, I'm Ashraful</h1>
      <p className={styles.subHeading}>
        A full-stack software developer based in Bangkok, Thailand.
      </p>
      <p className="lead">
        Currently building cool things at{" "}
        <a href="https://anymindgroup.com/">AnyMind Group.</a>
      </p>
      <p className={styles.bioText}>
        With over eight years of experience in web and mobile application
        development, I have worked extensively with advanced front-end and
        back-end technologies such as Angular, React, React Native, Node.js, and
        TypeScript.
      </p>
      <p className={styles.bioText}>
        I'm deeply passionate about the inner workings of all things related to
        web and mobile application development. I love to create feature-rich,
        responsive, and scalable web applications.
      </p>
      <p className={styles.bioText}>
        Most of my free time I love to share my findings with the community so
        the others from the same pitch of my technical experience might be able
        to use those findings and go forward. So don't forget to have a look on
        my
        <a className={styles.socialLink} href="https://medium.com/@imasharaful">
          {" "}
          medium{" "}
        </a>
        blog.
      </p>
    </>
  );
}

export default DefaultInfo;
